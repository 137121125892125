@import './SharedStyles.css';

.competition-registration-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 64px);
  padding: 2rem;
  background-color: #1a1a1a;
}

.competition-registration-title {
  font-size: 2rem;
  margin-bottom: 1.5rem;
  color: #ffffff;
}

.competition-registration-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 400px;
}

.competition-registration-input {
  width: 100%;
  padding: 0.75rem;
  margin-bottom: 1rem;
  background-color: #2a2a2a;
  border: 1px solid #3a3a3a;
  color: #ffffff;
  border-radius: 4px;
  font-size: 1rem;
}

.competition-registration-button {
  background-color: #4CAF50;
  border: none;
  color: white;
  padding: 0.75rem 1.5rem;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 1rem;
  margin-top: 1rem;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.competition-registration-button:hover {
  background-color: #45a049;
}