.google-sign-in-button {
    display: flex;
    align-items: center;
    background-color: #fff;
    border: 1px solid #dadce0;
    border-radius: 4px;
    padding: 10px 16px;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-weight: 500;
    color: #3c4043;
    cursor: pointer;
    transition: background-color 0.3s;
    margin: 12px auto;
  }
  
  .google-sign-in-button:hover {
    background-color: #f7f8f8;
  }
  
  .google-sign-in-button img {
    width: 18px;
    height: 18px;
    margin-right: 8px;
  }
  
  .google-sign-in-button span {
    flex-grow: 1;
    text-align: center;
  }