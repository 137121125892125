.nav {
    background-color: #121212;
    padding: 1rem 0;
  }
  
  .nav-container {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 2rem;
  }
  
  .nav-logo, .nav-auth {
    flex-basis: 33.33%;
  }
  
  .nav-logo {
    color: #ffffff;
    font-size: 1.5rem;
    font-weight: bold;
    text-decoration: none;
    text-align: left;
    display: flex;
    align-items: center;
  }
  
  .nav-logo img {
    width: 32px;
    height: 32px;
    margin-right: 10px;
    filter: invert(1);
  }
  
  .nav-links {
    display: flex;
    justify-content: center;
    flex-grow: 1;
  }
  
  .nav-link {
    color: #a0a0a0;
    text-decoration: none;
    font-size: 1rem;
    transition: color 0.3s ease;
    margin: 0 auto;
    display: flex;
    align-items: center;
    text-align: center;
  }
  
  .nav-link:hover {
    color: #ffffff;
  }
  
  .nav-auth {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
  }

  .nav-button {
    background-color: transparent;
    border: 1px solid #a0a0a0;
    color: #a0a0a0;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    text-decoration: none;
    font-size: 1rem;
    transition: all 0.3s ease;
  }
  
  .nav-button:hover {
    background-color: #a0a0a0;
    color: #1a1a1a;
  }
  
  @media (max-width: 768px) {
    .nav-container {
      flex-direction: column;
      align-items: center;
      padding: 1rem;
    }
  
    .nav-logo, .nav-links, .nav-auth {
      flex-basis: 100%;
      width: 100%;
      margin-bottom: 1rem;
      text-align: center;
    }
  
    .nav-links {
      flex-direction: column;
      gap: 0.5rem;
    }
  
    .nav-auth {
      flex-direction: column;
      align-items: center;
    }
  
    .nav-button {
      /* width: 100%; */
      margin-bottom: 0.5rem;
    }
  }

  .nav-toggle {
    display: none;
    background: none;
    border: none;
    color: #ffffff;
    font-size: 1.5rem;
    cursor: pointer;
  }
  
  @media (max-width: 768px) {
    .nav-toggle {
      display: block;
      position: absolute;
      right: 1rem;
    }
  
    .nav-links, .nav-auth {
      display: none;
    }
  
    .nav-links.active, .nav-auth.active {
      display: flex;
    }
  }