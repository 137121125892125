@import './SharedStyles.css';

.forgot-password-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.message {
  margin-top: 1rem;
  color: #4CAF50;
}