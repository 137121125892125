@import './SharedStyles.css';

.login-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.forgot-password {
  color: #a0a0a0;
  text-decoration: none;
  margin-top: 1rem;
  font-size: 0.9rem;
}

.forgot-password:hover {
  color: #ffffff;
}