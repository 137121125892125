.landing-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #1a1a1a;
    color: #ffffff;
    box-sizing: border-box;
    padding: 2rem;
  }
  
  .landing-content {
    text-align: center;
    max-width: 600px;
  }
  
  .landing-title {
    font-size: 3rem;
    margin-bottom: 1rem;
  }
  
  .landing-description {
    font-size: 1.2rem;
    margin-bottom: 2rem;
    line-height: 1.6;
  }
  
  .landing-button {
    background-color: #4CAF50;
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s ease;
  }
  
  .landing-button:hover {
    background-color: #45a049;
  }