@import './SharedStyles.css';

.register-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.terms {
  font-size: 0.8rem;
  color: #a0a0a0;
  margin-top: 1rem;
}

.terms a {
  color: #4CAF50;
  text-decoration: none;
}

.terms a:hover {
  text-decoration: underline;
}