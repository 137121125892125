.selection-page-container {
    padding: 2rem;
    background-color: #1a1a1a;
    min-height: calc(100vh - 64px);
    color: #ffffff;
  }
  
  .selection-page-title {
    font-size: 2rem;
    margin-bottom: 1.5rem;
    text-align: center;
  }
  
  .user-selection-title {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    text-align: center;
  }
  
  .selection-form {
    max-width: 800px;
    margin: 0 auto;
  }
  
  .fixtures-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .fixture-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #2a2a2a;
    padding: 1rem;
    border-radius: 8px;
  }
  
  .team-button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    border: none;
    color: #ffffff;
    cursor: pointer;
    padding: 0.5rem;
    border-radius: 4px;
    transition: background-color 0.3s ease;
    width: 100%;
    font-size: 18px;
  }
  
  .team-button:hover {
    background-color: #3a3a3a;
  }
  
  .team-button.selected {
    background-color: #4CAF50;
  }
  
  .team-button.disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  .team-button .greyed-out {
    filter: grayscale(100%);
  }
  
  .team-logo {
    width: 24px;
    height: 24px;
    margin: 0 0.5rem;
  }
  
  .vs {
    font-weight: bold;
    text-align: center;
  }
  
  .selection-summary {
    margin-top: 2rem;
    text-align: center;
  }
  
  .submit-button {
    background-color: #4CAF50;
    border: none;
    color: white;
    padding: 0.75rem 1.5rem;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 1rem;
    margin-top: 1rem;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s ease;
  }
  
  .submit-button:hover {
    background-color: #45a049;
  }
  
  .submit-button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }

  .current-selection {
    font-size: 1.2rem;
    margin-bottom: 1rem;
    text-align: center;
    color: #4CAF50;
  }

@media (max-width: 768px) {
  .fixture-item {
    flex-direction: column;
    gap: 0.5rem;
  }

  .team-button {
    font-size: 16px;
  }

  .team-logo {
    width: 20px;
    height: 20px;
    margin: 0 0.25rem;
  }

  .vs {
    margin: 0.5rem 0;
  }
}